@font-face {
  font-family: OpenSans;
  src:
    url('/../assets/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
    url('/../assets/fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
    url('/../assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: OpenSansSemiBold; // Font-weight 600
  src:
    url('/../assets/fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
    url('/../assets/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'),
    url('/../assets/fonts/OpenSans/OpenSans-SemiBold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: OpenSansBold; // Font-weight 700
  src:
    url('/../assets/fonts/OpenSans/OpenSans-Bold-webfont.woff2') format('woff2'),
    url('/../assets/fonts/OpenSans/OpenSans-Bold-webfont.woff') format('woff'),
    url('/../assets/fonts/OpenSans/OpenSans-Bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MuseoBold;
  src:
    url('/../assets/fonts/Museo-Cyrillic/Museo-Cyrl-700-webfont.woff2') format('woff2'),
    url('/../assets/fonts/Museo-Cyrillic/Museo-Cyrl-700-webfont.woff') format('woff'),
    url('/../assets/fonts/Museo-Cyrillic/Museo-Cyrl-700-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MuseoLight;
  src:
    url('/../assets/fonts/Museo-Cyrillic/Museo-Cyrl-300-webfont.woff2') format('woff2'),
    url('/../assets/fonts/Museo-Cyrillic/Museo-Cyrl-300-webfont.woff') format('woff'),
    url('/../assets/fonts/Museo-Cyrillic/Museo-Cyrl-300-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
