@font-face {
  font-family: OpenSans;
  src:
    url('/../../assets/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
    url('/../../assets/fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
    url('/../../assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: OpenSansSemiBold; // Font-weight 600
  src:
    url('/../../assets/fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
    url('/../../assets/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'),
    url('/../../assets/fonts/OpenSans/OpenSans-SemiBold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: OpenSansBold; // Font-weight 700
  src:
    url('/../../assets/fonts/OpenSans/OpenSans-Bold-webfont.woff2') format('woff2'),
    url('/../../assets/fonts/OpenSans/OpenSans-Bold-webfont.woff') format('woff'),
    url('/../../assets/fonts/OpenSans/OpenSans-Bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Museo;
  src:
    url('/../../assets/fonts/Museo-Cyrillic/Museo-Cyrl-500-webfont.woff2') format('woff2'),
    url('/../../assets/fonts/Museo-Cyrillic/Museo-Cyrl-500-webfont.woff') format('woff'),
    url('/../../assets/fonts/Museo-Cyrillic/Museo-Cyrl-500-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MuseoBold;
  src:
    url('/../../assets/fonts/Museo-Cyrillic/Museo-Cyrl-700-webfont.woff2') format('woff2'),
    url('/../../assets/fonts/Museo-Cyrillic/Museo-Cyrl-700-webfont.woff') format('woff'),
    url('/../../assets/fonts/Museo-Cyrillic/Museo-Cyrl-700-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MuseoBlack;
  src:
    url('/../../assets/fonts/Museo-Cyrillic/Museo-Cyrl-900-webfont.woff2') format('woff2'),
    url('/../../assets/fonts/Museo-Cyrillic/Museo-Cyrl-900-webfont.woff') format('woff'),
    url('/../../assets/fonts/Museo-Cyrillic/Museo-Cyrl-900-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MuseoLight;
  src:
    url('/../../assets/fonts/Museo-Cyrillic/Museo-Cyrl-300-webfont.woff2') format('woff2'),
    url('/../../assets/fonts/Museo-Cyrillic/Museo-Cyrl-300-webfont.woff') format('woff'),
    url('/../../assets/fonts/Museo-Cyrillic/Museo-Cyrl-300-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-size: 1rem;
  font-family: $font-family-base;
  line-height: 1.5;
  color: $fe-black;
  background-color: $fe-multitude-blue;
}

h1,
.h1 {
  font-family: $font-family-fancy-bold;
  color: $fe-klein-blue;
  max-width: 1200px;
  margin: 0;
  text-align: center;
}

h2,
h3,
h4,
h5,
h6,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0 0 1rem;
  line-height: 120%;
  color: $fe-klein-blue;
  font-family: $font-family-fancy;
}

// mobile font sizes
h2,
.h2 {
  font-size: $font-size-h2;
}

h3,
.h3 {
  font-size: $font-size-h3;
}

h4,
.h4 {
  font-size: $font-size-h4;
}

h5,
.h5 {
  font-size: $font-size-h5;
}

h6,
.h6 {
  font-size: $font-size-h6;
}

// font size for devices with width from 581px to 959px
@media (min-width: $fe-breakpoint-small-start) {
  h1,
  .h1 {
    font-size: $font-size-tablet-h1;
  }

  h2,
  .h2 {
    font-size: $font-size-tablet-h2;
  }

  h3,
  .h3 {
    font-size: $font-size-tablet-h3;
  }

  h4,
  .h4 {
    font-size: $font-size-tablet-h4;
  }

  h5,
  .h5 {
    font-size: $font-size-tablet-h5;
  }

  h6,
  .h6 {
    font-size: $font-size-tablet-h6;
  }
}

// h1 font size for devices with width from 960px and up
@media (min-width: $fe-breakpoint-large-start) {
  h1,
  .h1 {
    font-size: $font-size-desktop-h1;
  }

  h2,
  .h2 {
    font-size: $font-size-desktop-h2;
  }

  h3,
  .h3 {
    font-size: $font-size-desktop-h3;
  }

  h4,
  .h4 {
    font-size: $font-size-desktop-h4;
  }

  h5,
  .h5 {
    font-size: $font-size-desktop-h5;
  }

  h6,
  .h6 {
    font-size: $font-size-desktop-h6;
  }
}

blockquote {
  margin: 0 0 0 20px;
  padding: 20px 20px 20px 40px;
  box-shadow: inset 2px 0 0 0 $fe-white-smoke;
  font-family: $font-family-fancy;
  font-size: $font-size-20;
  font-weight: 600;
  font-style: normal;
  line-height: 1.35;
  color: $fe-black;
  position: relative;
  z-index: 1;

  @media (min-width: $fe-breakpoint-small-start) {
    width: 40%;
    float: right;
    font-size: $font-size-22;
  }

  &::before {
    @include abs-position();

    font-family: Georgia, serif;
    content: '\201C';
    color: $fe-white-smoke;
    font-size: 110px;
    display: inline;
    margin-top: -45px;
    margin-left: -20px;
    z-index: -1;
  }
}

small,
.small {
  font-size: $small-font-size;
}

a {
  color: $fe-klein-blue;
  font-family: $font-family-base;
  text-decoration: none;

  &:hover,
  &:active {
    color: $fe-carrot;
  }
}

.box-title {
  padding-bottom: $default-component-padding;
  margin-bottom: 0;
  text-align: center;
}

.light {
  font-family: $font-family-fancy-light;
}

// color classes used for text colors
.fe-white {
  color: $fe-white;
}

.fe-blue {
  color: $fe-klein-blue;
}

.fe-orange {
  color: $fe-carrot;
}

.fe-black {
  color: $fe-black;
}
