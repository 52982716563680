//set value for the height of the mobile menu position
$mobile-header-height: 60px;

html {
  box-sizing: border-box;

  @media (orientation: landscape) and (max-height: 550px) {
    -webkit-text-size-adjust: 100%;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body,
.fe-body {
  margin: 0;
  width: 100%;
  -webkit-text-size-adjust: none;
}

body.body-overflow {
  overflow: hidden;

  @media (min-width: $fe-breakpoint-large-start) {
    overflow: unset;
  }
}

img {
  max-width: 100%;
}

.full-width {
  width: 100%;
}

.fe-container {
  margin: 0 auto;
  width: calc(100% - 32px);
  max-width: 1200px;

  &.header {
    h1 {
      padding-top: 60px;
    }
  }
}

footer .fe-container .fe-container {
  margin: 0;
}

.fe-container-paragraph {
  margin: 0 auto 40px;
  width: calc(100% - 32px);
  max-width: 880px;
}

.fe-hidden {
  visibility: hidden;
}

@media (min-width: $fe-breakpoint-small-start) { // UN-310
  .card-grid {
    @include flexbox($justify-content: space-between);

    .card-col {
      width: 46%;
    }
  }
}
