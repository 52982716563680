@import './constant';

.fe-btn-primary,
.fe-btn-secondary,
.fe-btn-tertiary,
.fe-btn-custom,
.fe-btn-outline {
  cursor: pointer;
  font-family: $font-family-semi-bold;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  user-select: none;
  padding: 12px 20px;
  border: 1px solid transparent;
  transition: all 0.2s linear;
  border-radius: 100px;
  outline: none;
  font-size: $font-size-18;

  &.fe-btn-small {
    padding: 10px 20px;
    font-size: $font-size-16;
  }

  &.not-available {
    cursor: default;
  }

  @media (min-width: $fe-breakpoint-small-start) {
    min-width: $fe-min-button-size-default;
    width: auto;
  }
}

// fe-btn-primary / fe-btn-secondary / ...
// Usage example: <a class="fe-btn-primary fe-btn-small">
// mixin values:
//@include button-enabled(text-color, background-color, border-color, box-shadow (predefined but can be button-specific), border-width (also predefined but can be button-specific for ex fe-btn-custom)
//@include hover(same as for enabled);
//@include active(same as for enabled);
//@include not-available(same as for enabled);

.fe-btn-primary {
  @include button-enabled($fe-white, $fe-carrot, $fe-carrot);
  @include hover($fe-white, $fe-yellow-orange, $fe-yellow-orange);
  @include active($fe-white, $fe-cheeseball, $fe-cheeseball);
  @include not-available($fe-white, $fe-negroni, $fe-negroni);

  &.fe-btn-border {
    border-color: $fe-white;
  }

  &.touch-device {
    @include touch-device ($fe-white, $fe-carrot, $fe-carrot);
  }
}

.fe-btn-secondary {
  @include button-enabled($fe-carrot, transparent, $fe-carrot, none);
  @include hover($fe-yellow-orange, rgba(255, 255, 255, 0.1), $fe-yellow-orange);

  &.touch-device {
    @include touch-device ($fe-carrot, transparent, $fe-carrot);
  }
}

.fe-btn-tertiary {
  @include button-enabled($fe-tundora, $fe-white, $fe-white-smoke, $border-width: 1px);
  @include hover($fe-nobel, $fe-white, $fe-white-smoke, $border-width: 1px);
  @include active($fe-nobel, $fe-white, $fe-white-smoke, $border-width: 1px);

  &.touch-device {
    @include touch-device ($fe-tundora, $fe-white, $fe-white-smoke, $border-width: 1px);
  }
}

.fe-btn-custom {
  padding: 13px 20px;

  &.fe-btn-small {
    padding: 11px 20px;
  }

  @include button-enabled($fe-carrot, $fe-white, $fe-white-smoke, $border-width: 1px);
  @include hover($fe-yellow-orange, $fe-white, $fe-white-smoke, $border-width: 1px);
  @include active($fe-yellow-orange, $fe-white, $fe-white-smoke, $border-width: 1px);
  @include not-available($fe-cheeseball, $fe-white, $fe-white-smoke, $border-width: 1px);

  &.touch-device {
    @include touch-device ($fe-carrot, $fe-white, $fe-white-smoke, $border-width: 1px);
  }
}

.fe-btn-outline {
  @include button-enabled($fe-white, transparent, $fe-white);
  @include hover($fe-white-smoke, rgba(255, 255, 255, 0.05), $fe-white-smoke);

  &.touch-device {
    @include touch-device ($fe-white, transparent, $fe-white);
  }
}

.full-profile-button {
  border-radius: 100px;

  @include button-enabled($fe-white, $fe-carrot, transparent);
  @include hover($fe-carrot, $fe-white, $fe-carrot);

  padding: 0;
  min-width: 100px;

  a {
    justify-content: center;
    text-transform: capitalize;
    padding: 10px 15px;

    @media (min-width: $fe-breakpoint-large-start) {
      padding: 10px 30px;
    }
  }
}
