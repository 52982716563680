@import "rem-mixin";
@import "mixins";

$viewport-width: pixel-to-rem(868);
$header-height-desktop: pixel-to-rem(-80);
$header-height-mobile: -17rem;
$footer-height: pixel-to-rem(66);
$form-header-padding: 5px;
$formcard-header-padding-offset: 15px;
$formcard-mobile-header-padding-offset: 10px;
$formcard-mobile-header-offset: 38px;
$form-header-container-height: 17px;

$fe-success-green: #44af69;
$fe-light-grey: #eee;
$fe-smart-blue: #011e81;
$fe-smart-light-blue: #6780d4;
$fe-radio-size: 20px;
$fe-midnight-blue: #01165c;
$fe-sunset-orange: #ff4b3e;
$fe-multitude-blue: #89cfbe !default;
$fe-klein-blue: #022bb8 !default;
$fe-white: #fff !default;
$fe-black: #000 !default;
$fe-alto: #dbdbdb !default;
$fe-carrot: #fb9116 !default;
$fe-white-smoke: #f2f2f2 !default;
$fe-chateau-green: #44af69 !default;
$fe-red: #f00 !default;
$fe-valencia: #d54730 !default;
$fe-nobel: #979797 !default;
$fe-dark-blue: #001458 !default;
$fe-heavy-metal: #1d1d1b !default;
$fe-tundora: #565656 !default;
$fe-natural-gray: #8e8e8d;
$fe-gold: #b39f4e !default;
$fe-scarlet: #ff1a00 !default;
$fe-river: #425060 !default;
$fe-heavy-light: #8c8c8c !default;

$fe-black-87-opacity: rgba($fe-black, 0.87) !default;
$fe-white-87-opacity: rgba($fe-white, 0.87) !default;

$transition-default: 0.3s ease;

$font-size-9: fontsize(9px);
$font-size-10: fontsize(10px);
$font-size-12: fontsize(12px);
$font-size-13: fontsize(13px);
$font-size-14: fontsize(14px);
$font-size-16: fontsize(16px);
$font-size-18: fontsize(18px);
$font-size-20: fontsize(20px);
$font-size-22: fontsize(22px);
$font-size-24: fontsize(24px);
$font-size-28: fontsize(28px);
$font-size-90: fontsize(90px);

$font-size-h5: fontsize(18px) !default; // 18/16 px
$font-size-h6: fontsize(16px) !default; // 16/16 px

$small-font-size: 80% !default;
$extra-small-font-size: 65% !default;

$font-family-base: OpenSans, sans-serif !default;
$font-family-semi-bold: OpenSansSemiBold, sans-serif !default;
$font-family-bold: OpenSansBold, sans-serif !default;
$font-family-fancy-bold: MuseoBold, sans-serif !default;
$font-family-fancy-light: MuseoLight, sans-serif !default;

// Media queries

$fe-breakpoint-extra-small-header-start: 620px;
$fe-breakpoint-extra-small-end: 580px;
$fe-breakpoint-small-start: 581px;
$fe-breakpoint-small-end: 767px;
$fe-breakpoint-large-start: 960px;
$fe-breakpoint-medium-start: 768px;
$fe-breakpoint-medium-end: 959px;
$fe-breakpoint-extra-large-start: 1240px;
