@import './constant';

.mat-input {
  color: $fe-nobel;
}

.mat-form-field {
  width: 100%;
  line-height: normal;
  color: $fe-black;
  font: {
    family: $font-family-base;
    weight: normal;
    size: 13px;
  }
}

.underline-disable {
  .mat-form-field-underline {
    display: none;
  }
}

.mat-form-field-label {
  color: $fe-black;
}

.mat-checkbox-layout {
  white-space: inherit !important; // overwrites native material styling
  margin-bottom: 16px;

  .mat-checkbox-inner-container {
    width: 20px;
    height: 20px;
    margin: 0 auto !important; // overwrites native material styling
    margin-right: 8px !important; // overwrites native material styling
    top: 6px !important; // overwrites native material styling
  }
}

.mat-checkbox-frame {
  width: 20px;
  height: 20px;
  border: solid 2px $fe-carrot;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba($fe-carrot, 0.5);
}

.mat-checkbox-checked {
  .mat-checkbox-frame {
    width: 20px;
    height: 20px;
    box-shadow: none;
    border: none;
  }

  .mat-checkbox-inner-container {
    width: 20px;
    height: 20px;
  }

  .mat-checkbox-checkmark {
    top: -1px;
  }

  .mat-checkbox-checkmark-path {
    stroke: {
      width: 4;
      dasharray: 21.5;
    }
  }
}

.mat-checkbox.ng-invalid.ng-touched label.mat-checkbox-layout {
  color: $fe-pomegranate;
}

.mat-expansion-panel-content {
  font: inherit !important; // overwrites native material styling
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 100%;
}

.mat-select-panel {
  padding-top: 20px;
  overflow-y: auto;

  .mat-option {
    color: $fe-black;
    padding: 8px;
    cursor: pointer;

    &.mat-selected:not(.mat-option-multiple) {
      background: $fe-white-smoke;
    }

    .mat-option-text {
      font-family: $font-family-base;

      @include flexbox(center);

      flex: 1;
    }
  }

  .mat-option:first-child {
    margin-top: 0;
  }

  .mat-option:last-child {
    margin-bottom: 0;
  }

  .active {
    width: 25px;
    height: 25px;
    display: inline-flex;
    border-radius: 12px;
    background-color: $fe-white;
    border: solid 1px $fe-nobel;
    align-items: center;

    @include abs-position($right: 10px);

    span {
      width: 15px;
      height: 15px;
      margin: auto;
      border-radius: 12px;
      background-color: $fe-carrot;
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($fe-black, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $fe-nobel;
  }

  &::-webkit-scrollbar:vertical {
    width: 10px;
  }
}

.mat-radio-button {
  font-family: $font-family-base;
  font-size: $font-size-13;
  width: 100%;
  margin: 10px 0;

  .mark-invalid & {
    .mat-radio-outer-circle {
      border: 2px solid $fe-pomegranate !important; // overwrites native material styling
    }
  }

  .mat-radio-container {
    width: 24px;
    height: 24px;

    .mat-radio-outer-circle {
      width: 24px;
      height: 24px;
      border: 1px solid $fe-nobel;
    }

    .mat-radio-inner-circle {
      width: 24px;
      height: 24px;
    }
  }

  &.mat-accent {
    &.mat-radio-checked {
      font-family: $font-family-bold;

      .mat-radio-outer-circle {
        border-color: $fe-nobel;
      }
    }
  }
}

.mat-form-field-appearance-legacy {
  .mat-form-field-label {
    color: $fe-black;
  }
}

@media (min-width: $fe-breakpoint-large-start) {
  .large-radio-button {
    .mat-radio-container {
      display: none;
    }

    .mat-radio-label-content {
      padding: 0;
    }
  }

  .country-form-se {
    .large-radio-button {
      .mat-radio-label-content {
        padding: 8px 50px;
      }
    }
  }
}

.mat-form-field-disabled .mat-form-field-underline {
  border-bottom: 1px solid $fe-nobel;
  background-image: none;
}

fe-input-datepicker {
  .mat-form-field-subscript-wrapper {
    display: none;
  }

  .mat-input-element:disabled {
    opacity: 1;
    -webkit-text-fill-color: $fe-black;
    padding-left: 25px;
  }

  .mat-error {
    &.calc-error {
      font-size: $extra-small-font-size;
      margin-top: 5px;
    }
  }

  .mat-form-field-disabled {
    &.mat-form-field-should-float {
      .mat-form-field-infix {
        margin-left: -24px;
      }
    }
  }

  mat-datepicker-toggle {
    color: $fe-carrot;
    font-size: $font-size-16;
  }

  .mat-form-field-flex {
    flex-direction: row-reverse;
  }
}

.mat-radio-group.inline {
  min-height: 66px;
  display: inline-flex;

  .mat-radio-button {
    padding-right: 20px;
  }
}

.mat-select {
  .mat-select-trigger {
    font-family: $font-family-base;
  }
}
