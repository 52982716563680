@import '@angular/material/theming';
@import "constants";

$mat-carrot: (
  50 : #fff2e3,
  100 : #fedeb9,
  200 : #fdc88b,
  300 : #fcb25c,
  400 : #fca239,
  500 : #fb9116,
  600 : #fa8913,
  700 : #fa7e10,
  800 : #f9740c,
  900 : #f86206,
  A100 : #fedeb9,
  A200 : #fca239,
  A400 : #fa7e10,
  A700 : #f86206,
  contrast: (
    50: $fe-black-87-opacity,
    100: $fe-black-87-opacity,
    200: $fe-black-87-opacity,
    300: $fe-black-87-opacity,
    400: $fe-black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $fe-white-87-opacity,
    900: $fe-white-87-opacity,
    A100: $fe-black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-saphire-blue: (
  50 : #e0e4ef,
  100 : #b3bbd7,
  200 : #808dbc,
  300 : #4d5fa1,
  400 : #263d8c,
  500 : #001b78,
  600 : #001870,
  700 : #001465,
  800 : #00105b,
  900 : #000848,
  A100 : #7c81ff,
  A200 : #4950ff,
  A400 : #161eff,
  A700 : #000848,
  contrast: (
    50: $fe-black-87-opacity,
    100: $fe-black-87-opacity,
    200: $fe-black-87-opacity,
    300: $fe-black-87-opacity,
    400: $fe-black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $fe-white-87-opacity,
    900: $fe-white-87-opacity,
    A100: $fe-black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-klein-blue: (
  50 : #e1e6f6,
  100 : #b3bfea,
  200 : #8195dc,
  300 : #4e6bcd,
  400 : #284bc3,
  500 : #022bb8,
  600 : #0226b1,
  700 : #0120a8,
  800 : #011aa0,
  900 : #011091,
  A100 : #bdc0ff,
  A200 : #8a90ff,
  A400 : #5760ff,
  A700 : #3d47ff,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #fff,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #fff,
    A700 : #fff,
  )
);

// Include the base styles for Angular Material core. We include this here so that you only have to load a single css file for Angular Material in your app.

@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss (imported above). For each palette, you can optionally specify a default, lighter, and darker hue.

$fe-app-primary: mat-palette($mat-saphire-blue);
$fe-app-accent: mat-palette($mat-carrot, A200, A100, A400);
// The warn palette is optional (defaults to red).
$fe-app-warn: mat-palette($mat-red);

// Colors/Tones
$fe-primary-tone: mat-color($fe-app-primary, 700);
$fe-accent-tone: mat-color($fe-app-accent, 700);
$fe-warn-tone: mat-color($fe-app-warn, 700);

// Create the theme object (a Sass map containing all of the palettes).
$fe-app-theme: mat-light-theme($fe-app-primary, $fe-app-accent, $fe-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($fe-app-theme);

.mat-radio-button.mat-radio-disabled {
  .mat-radio-outer-circle {
    border-color: $fe-alto !important;
  }
}
