$primary-box-shadow: 0 6px 6px -4px rgba(86, 86, 86, 0.2);
$hover-box-shadow: 0 8px 8px -4px rgba(29, 29, 27, 0.2);
$disabled-box-shadow: 0 4px 4px -4px rgba(29, 29, 27, 0.2);

@mixin button-enabled($color, $bg-color, $border-color, $box-shadow: $primary-box-shadow, $border-width: 2px) {
  color: $color;
  background-color: $bg-color;
  border: $border-width solid $border-color;
  box-shadow: $box-shadow;
}

@mixin hover($color, $bg-color, $border-color, $box-shadow: $hover-box-shadow, $border-width: 2px) {
  &:hover {
    color: $color;
    background-color: $bg-color;
    border: $border-width solid $border-color;
    box-shadow: $box-shadow;
    text-decoration: none;
  }
}

@mixin active($color, $bg-color, $border-color, $box-shadow: $primary-box-shadow, $border-width: 2px) {
  &:active {
    color: $color;
    background-color: $bg-color;
    border: $border-width solid $border-color;
    box-shadow: $box-shadow;
    text-decoration: none;
  }
}

@mixin not-available($color, $bg-color, $border-color, $box-shadow: $disabled-box-shadow , $border-width: 2px) {
  &.not-available {
    color: $color;
    background-color: $bg-color;
    border: $border-width solid $border-color;
    box-shadow: $box-shadow;
  }
}

@mixin touch-device($touch-color, $touch-bg-color, $touch-border-color, $box-shadow: $primary-box-shadow, $border-width: 2px) {
  &.touch-device {
    color: $touch-color;
    background-color: $touch-bg-color;
    border: $border-width solid $touch-border-color;
    box-shadow: $box-shadow;
  }
}

// Flexbox container mixin: generate flex layout
// Usage example: @include flexbox(center, center, row);
// 'null' -- means the value is optional, does not need to be included when the mixin is used --- please note that the value should be given in the same order as defined in mixin.
// @include flexbox(row); -- will compile to: align-items: row.
// you can skip any null value, and reference specific values this way:   @include flexbox($justify-content: space-between);

@mixin flexbox($align-items: null, $justify-content: null, $flex-direction: null, $flex-wrap: null) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
}

@mixin abs-position($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// fe-marker-color:
// Generates fe-marker colors - bullet lists - for children of both ol and ul (li > before)
// Usage example:
// @include fe-marker-color(':before',0 0 0 3px transparentize($fe-white, 0.83) inset,#{$fe-white})

@mixin fe-marker-color($pseudo: null,$box-shadow: null, $color: null) {
  list-style: none;

  & > li {
    &#{$pseudo} {
      color: $color;
      box-shadow: $box-shadow;
    }
  }
}

// Use this mixin to create variables for font-sizes, where the pr value is automatically calculated into rem value when compiling
// Usage: $p-variable: { fontsize(14px)} --- but please try to use existing variables from _constant.scss sheet.

@function fontsize($size) {
  $remSize: $size / 16px;

  @return #{$remSize}rem;
}

// account page mixin

@mixin account-page {
  fe-card {
    .card-header {
      @include flexbox();

      h6 {
        display: none;

        @media (min-width: $fe-breakpoint-large-start) {
          margin: 1px 0 20px;
          display: inline-block;
        }
      }
    }

    .fe-card-content-main {
      display: block;
      padding: $default-box-padding-mobile;

      @media (min-width: $fe-breakpoint-large-start) {
        padding: $default-box-padding;
        border: 1px dashed $fe-alto;
      }

      .fe-btn-primary,
      .fe-btn-secondary {
        // Multiline buttons if not enough horizontal space
        white-space: normal;
      }
    }
  }
}

// body account container mixin

@mixin fe-body-account-container {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

// animations' keyframes

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translate(0, 40px);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
