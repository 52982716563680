@import "constant";

.fe-subtitle {
  font-size: $font-size-20;
  font-family: $font-family-fancy-light;
  max-width: 1200px;
  margin: 1em auto;
  text-align: center;
  color: $fe-klein-blue;
  padding: 0 20px;

  @media (min-width: $fe-breakpoint-small-start) {
    font-size: $font-size-22;
  }

  @media (min-width: $fe-breakpoint-medium-start) {
    padding: 0 70px;
  }

  @media (min-width: $fe-breakpoint-large-start) {
    padding: 0 100px;
    font-size: $font-size-28;
  }
}

.fe-loader {
  color: $fe-dark-blue;
  background: $fe-white-smoke url('/assets/images/no-check-rolling.svg') no-repeat center center;
  text-align: center;
  padding: 200px 0 60px;

  @media (min-width: $fe-breakpoint-small-start) {
    margin: 50px;
  }
}

.fe-detail-list-global-styling {
  overflow: hidden;

  ul {
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: grid;

    li {
      list-style-type: none;
      padding: 0 0 5px 5px;

      label {
        color: $fe-nobel;
        font-size: $font-size-14;
        font-family: $font-family-base;
        display: block;
      }

      .value {
        color: $fe-river;
        font-size: $font-size-16;
        font-family: $font-family-semi-bold;
        word-break: break-word;
        display: block;
      }
    }
  }

  .button-container {
    height: auto;
    grid-column-start: 1;
    grid-column-end: 3;

    @media (min-width: $fe-breakpoint-small-start) {
      grid-column-start: 2;
    }
  }

  &.columns-1 {
    ul {
      @include flexbox($flex-direction: column, $flex-wrap: wrap);

      li {
        float: none;
        width: auto;
        text-align: center;
      }
    }
  }

  &.full-width ul li {
    @include flexbox($justify-content: space-between);

    height: 30px;
  }
}

.fe-profile-details { // UN-1202

  .income-and-expenses,
  .phone-number,
  .personal,
  .mailing-address,
  .email-address,
  .bank-account {
    padding-bottom: 20px;
    font-size: $font-size-12;
    font-family: $font-family-base;

    div:first-of-type {
      @include flexbox($justify-content: space-between);

      font-family: $font-family-fancy-bold;
      min-height: 30px;
    }

    .primary {
      padding: 5px;
      color: $fe-carrot;
      font-weight: bold;
      border-radius: 10px;
      background-color: transparentize($fe-carrot, 0.93);
      font-size: $font-size-9;
      margin-left: 10px;
      letter-spacing: 1px;
    }

    .fe-icon-edit {
      &::before {
        color: $fe-carrot;
        font-size: $font-size-18;
      }
    }
  }

  .new-phone-number,
  .new-mailing-address,
  .new-email-address,
  .new-bank-account {
    display: inline-flex;
    align-items: center;
    color: $fe-carrot;
    cursor: pointer;
    font-size: $font-size-14;
    padding: 20px 0;
    font-family: $font-family-base;

    span {
      padding-left: 5px;
    }
  }

  mat-expansion-panel {
    .mat-expansion-panel-header-description {
      flex-flow: row-reverse;
      color: $fe-carrot;
    }

    &.mat-expansion-panel {
      box-shadow: none;
    }

    mat-panel-title {
      @include flexbox(center);

      h6 {
        margin: 0;
      }
    }
  }
}

.fe-calculator {
  .calc-notice {
    a {
      color: $fe-white;
      text-decoration: underline;

      &:hover {
        color: $fe-carrot;
        text-decoration: none;
      }

      .fe-account-offer-page & {
        color: $fe-heavy-metal;
      }
    }
  }
}

.fe-invoices-filter-popup,
.fe-transaction-filter-popup {
  .fe-mat-select-popups {
    display: none !important; //overwriting material design
  }

  @media (min-width: $fe-breakpoint-small-start) {
    .fe-mat-select-popups {
      display: inline-block !important; //overwriting material design
    }

    .fe-native-select-popups {
      display: none;
    }
  }

  @media (orientation: landscape) and (max-height: $fe-breakpoint-extra-small-end) {
    .fe-mat-select-popups {
      display: none !important; //overriding material design
    }

    .fe-native-select-popups {
      display: inline-block;
    }
  }
}

.fe-ribbon-container {
  width: 360px;
}

.fe-corner-ribbon {
  @include abs-position(0, $left: -50px);

  width: 160px;
  transform: rotate(-45deg);
  font-size: $font-size-12;
  line-height: 13px;
  font-family: $font-family-base;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
  color: $fe-white-smoke;
  background-color: $fe-gold;
  box-shadow: 4px 3px 7px -5px rgba($fe-black, 0.71);
  padding: 10px 55px 10px;
  word-break: break-word;

  @media (min-width: $fe-breakpoint-large-start) {
    width: 300px;
    top: 10px;
    left: -100px;
    padding: 10px 100px 10px;
  }
}
