@import "constants";
@import "typography";

/* MATERIAL OVERRIDES */

.mat-radio-button {
  .mat-radio-container {
    width: $fe-radio-size;
    height: $fe-radio-size;

    .mat-radio-outer-circle {
      width: $fe-radio-size;
      height: $fe-radio-size;
      border: 2px solid $fe-carrot;
    }

    .mat-radio-inner-circle {
      width: $fe-radio-size;
      height: $fe-radio-size;
    }
  }

  &.mat-accent {
    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: $fe-carrot;
      }
    }
  }
}

.mat-checkbox-frame {
  box-shadow: none;
}

.mat-form-field {
  font-size: 16px;
}

.mat-form-field-appearance-legacy {
  .mat-form-field-label {
    color: $fe-nobel;
  }
}

/* END MATERIAL OVERRIDES */

.fe-section:not(:last-child) {
  padding-bottom: 2rem;
}

.fe-title {
  color: $fe-black;
}

.fe-box {
  min-height: 83px;
  border-radius: 2px;
  box-shadow: 0 0 8px 4px rgba(29, 29, 27, 0.04), inset 0 2px 0 0 $fe-carrot;
  border: solid 1px $fe-white-smoke;
  background-color: $fe-white;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;

  &.inactive {
    box-shadow: 0 0 8px 4px rgba(29, 29, 27, 0.04);

    .mat-radio-button .mat-radio-outer-circle {
      border-color: $fe-tundora;
    }
  }

  //&:not(:last-child) {
  margin-bottom: 1.5rem;
  //}

  &__switch {
    margin-top: -5px;
  }

  label {
    cursor: pointer;
  }

  &__body {
    font-family: $font-family-base;
    font-size: $font-size-h6;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $fe-black;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &-text,
    &-caption {
      display: flex;
      justify-content: space-between;
    }

    &-caption {
      font-size: 14px;
      color: $fe-nobel;
    }
  }

  &__icon {
    margin-left: -1.3rem;
    margin-right: pixel-to-rem(10);
  }

  &.disabled &__body,
  &.disabled &__body-caption {
    color: $fe-alto;
  }
}

.fe-btn-link {
  text-transform: none;
  cursor: pointer;

  &.fe-black {
    color: $fe-black;
    border-color: $fe-black;
  }
}

.fe-bottom-bar {
  display: flex;
  justify-content: space-between;

  &__right {
    justify-content: flex-end;
  }

  //.fe-btn-primary,
  //.fe-btn-secondary:not(:last-child) {
  //  margin-left: 1rem;
  //}
}

.fe-status {
  align-items: center;
  display: flex;
  font-size: $font-size-12;
  text-transform: uppercase;

  &__title {
    color: $fe-nobel;
    display: inline-block;
    padding: 0 8px 0 0;
  }

  &__state {
    border: solid 1px;
    border-radius: 4px;
    display: inline-block;
    padding: pixel-to-rem(5) pixel-to-rem(8);

    &.approved,
    &.created {
      border-color: $fe-chateau-green;
      color: $fe-chateau-green;
    }

    &.pending {
      border-color: $fe-carrot;
      color: $fe-carrot;
    }

    &.deleted,
    &.blocked {
      border-color: $fe-scarlet;
      color: $fe-scarlet;
    }
  }
}

.fe-link {
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  display: block;

  &-primary {
    color: $fe-carrot;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    display: block;

    &:hover {
      text-decoration: none;
    }
  }

  &-secondary {
    color: $fe-nobel;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    display: block;

    &:hover {
      text-decoration: none;
    }
  }

  &-third {
    color: $fe-white;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    display: block;

    &:hover {
      text-decoration: none;
    }
  }
}

.fe-drop-down-item {
  align-items: center;
  border-bottom: solid 1px $fe-white-smoke;
  display: flex;
  font-family: $font-family-semi-bold;
  font-size: $font-size-14;
  min-height: 38px;
  padding-left: pixel-to-rem(8);
}

.fe-drop-down-item.fe-status {
  font-size: $font-size-12;
}

.fe-drop-down-item:hover {
  background-color: $fe-white-smoke;
}

.fe-company-environment {
  border: solid 1px;
  border-radius: 4px;
  color: $fe-white;
  display: inline-block;
  font-size: $font-size-12;
  padding: pixel-to-rem(5) pixel-to-rem(8);
  text-transform: uppercase;

  &.prod {
    background-color: $fe-klein-blue;
  }

  &.sandbox {
    background-color: $fe-heavy-metal;
  }
}

@media (max-width: $fe-breakpoint-extra-small-end) {
  .fe-bottom-bar {
    &__left,
    &__right {
      width: 100%;
    }
  }
}

.fe-input-errors {
  color: $fe-red;
  font-size: 12px;
  margin-top: 5px;

  ul {
    list-style-type: none;
    text-align: left;
    padding: 0;
    margin: 0;
  }
}

.fe-notification-text {
  font-family: OpenSans, sans-serif;
  color: $fe-white;
  font-size: pixel-to-rem(16);
  word-break: break-word;
}

.fe-notification-link {
  font-family: OpenSans, sans-serif;
  color: $fe-white;
  font-size: pixel-to-rem(16);
  word-break: break-word;
  cursor: pointer;
  text-decoration: underline;
}

.fe-notification-link:active {
  color: $fe-white;
}
