@import "theme/material";
@import "theme/uniweb/main";
@import "theme/main";
@import "theme/constants";
@import '~ngx-toastr/toastr';

body {
  background-color: $fe-light-grey;
}

.pointer {
  cursor: pointer;
}

.mat-tooltip {
  font-size: 16px;
}

@media (max-width: $fe-breakpoint-extra-small-end) {
  .hidden-xsm {
    display: none;
  }
}
