@import "mixins";

// Color system
// for new color use http://chir.ag/projects/name-that-color/

$fe-black: #000 !default;
$fe-heavy-metal: #1d1d1b !default;
$fe-tundora: #565656 !default;
$fe-natural-gray: #8e8e8d;
$fe-nobel: #979797 !default;
$fe-alto: #dbdbdb !default;
$fe-white-smoke: #f2f2f2 !default;
$fe-white: #fff !default;
$fe-yellow-orange: #fca745 !default;
$fe-cheeseball: #fdbd73 !default;
$fe-negroni: #fee4c5 !default;

$fe-dark-blue: #001458 !default;
$fe-sapphire-blue: #001b78 !default;
$fe-multitude-blue: #89cfbe !default;
$fe-klein-blue: #022bb8 !default;
$fe-circle-blue: #274bc2 !default;
$fe-river: #425060 !default;
$fe-international-klein-blue: #0227a6 !default;

$fe-red: #f00 !default;
$fe-scarlet: #ff1a00 !default;
$fe-valencia: #d54730 !default;
$fe-carrot: #fb9116 !default;
$fe-pomegranate: #f44336 !default;
$fe-rose-white: #fff6f5 !default;

$fe-green: #009401 !default;
$fe-rose: #ea67a1 !default;
$fe-gold: #b39f4e !default;
$fe-chateau-green: #44af69 !default;

// colors with opacity

$fe-black-87-opacity: rgba($fe-black, 0.87) !default;
$fe-white-87-opacity: rgba($fe-white, 0.87) !default;
$fe-black-12-opacity: rgba($fe-black, 0.12) !default;
$fe-white-12-opacity: rgba($fe-white, 0.12) !default;
$fe-black-6-opacity: rgba($fe-black, 0.06) !default;
$fe-white-6-opacity: rgba($fe-white, 0.06) !default;

// Text

$font-family-base: OpenSans, sans-serif !default;
$font-family-bold: OpenSansBold, sans-serif !default;
$font-family-semi-bold: OpenSansSemiBold, sans-serif !default;
$font-family-fancy: Museo, sans-serif !default;
$font-family-fancy-bold: MuseoBold, sans-serif !default;
$font-family-fancy-black: MuseoBlack, sans-serif !default;
$font-family-fancy-light: MuseoLight, sans-serif !default;

// h1 - h6 tag font sizes - mobile

$font-size-h1: fontsize(36px) !default; // 36/16 px
$font-size-h2: fontsize(32px) !default; // 32/16 px
$font-size-h3: fontsize(24px) !default; // 24/16 px
$font-size-h4: fontsize(20px) !default; // 20/16 px
$font-size-h5: fontsize(18px) !default; // 18/16 px
$font-size-h6: fontsize(16px) !default; // 16/16 px

// h1 - h6 tag font sizes - tablets

$font-size-tablet-h1: fontsize(42px) !default; // 42/16 px
$font-size-tablet-h2: fontsize(34px) !default; // 34/16 px
$font-size-tablet-h3: fontsize(26px) !default; // 26/16px
$font-size-tablet-h4: fontsize(22px) !default; // 22/16 px
$font-size-tablet-h5: fontsize(18px) !default; // 18/16 px
$font-size-tablet-h6: fontsize(16px) !default; // 16/16 px

// h1 - h6 tag font sizes - desktop

$font-size-desktop-h1: fontsize(62px) !default;
$font-size-desktop-h2: fontsize(52px) !default;
$font-size-desktop-h3: fontsize(42px) !default;
$font-size-desktop-h4: fontsize(26px) !default;
$font-size-desktop-h5: fontsize(22px) !default;
$font-size-desktop-h6: fontsize(18px) !default;

// small or extra-small tag font size

$small-font-size: 80% !default;
$extra-small-font-size: 65% !default;

// p tag blog width

$fe-p-blog-width: 792px !default;

// Layout

$header-background-height: 262px;
$header-background-height-mobile: 280px;

// Button size

$fe-min-button-size-default: 179px;

// Grid

$default-box-padding-mobile: 10px;
$default-box-padding: 35px;
$default-component-padding: 50px;
$smaller-component-padding: 20px;

// Media queries

$fe-breakpoint-extra-small-start: 320px;
$fe-breakpoint-extra-small-end: 580px;
$fe-breakpoint-small-start: 581px;
$fe-breakpoint-small-end: 767px;
$fe-breakpoint-medium-start: 768px;
$fe-breakpoint-medium-end: 959px;
$fe-breakpoint-large-start: 960px;
$fe-breakpoint-large-end: 1239px;
$fe-breakpoint-extra-large-start: 1240px;

// Footer and header offsets

$global-header-offset: 60px;
$global-footer-offset: 64px;

$account-card-left-margin-mobile: 16px;
$account-card-left-margin-web: 48px;
$formcard-header-offset: 90px;
$formcard-mobile-header-offset: 38px;
$formcard-header-padding-offset: 15px;
$formcard-mobile-header-padding-offset: 10px;
$form-header-padding: 5px;
$form-header-container-height: 17px;

// Fonts variables

$font-size-0: 0;
$font-size-4: fontsize(4px);
$font-size-9: fontsize(9px);
$font-size-10: fontsize(10px);
$font-size-11: fontsize(11px);
$font-size-12: fontsize(12px);
$font-size-13: fontsize(13px);
$font-size-14: fontsize(14px);
$font-size-15: fontsize(15px);
$font-size-16: fontsize(16px);
$font-size-17: fontsize(17px);
$font-size-18: fontsize(18px);
$font-size-20: fontsize(20px);
$font-size-22: fontsize(22px);
$font-size-24: fontsize(24px);
$font-size-28: fontsize(28px);
$font-size-30: fontsize(30px);
$font-size-32: fontsize(32px);
$font-size-36: fontsize(36px);
$font-size-40: fontsize(40px);
$font-size-47: fontsize(47px);
$font-size-90: fontsize(90px);
